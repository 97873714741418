import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UserIcon } from "@heroicons/react/solid"; // Importing profile icon

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white shadow-md py-2 sticky top-0 z-50">
      {/* Sticky Navbar with shadow */}
      <div className="container mx-auto px-4">
        {/* Single Row for Desktop/iPad, Two Rows for Mobile */}
        <div className="flex justify-between items-center">
          {/* Left: MakerTerminal Icon */}
          <Link to="/" className="flex items-center">
            <img
              src={require("../images/mticon2.png")}
              alt="MakerTerminal Icon"
              className="h-8 w-8"
            />
          </Link>

          {/* Center: Navigation Links (Hidden on Mobile) */}
          <div className="hidden lg:flex lg:items-center lg:space-x-6 mx-auto">
            <Link
              to="/vcs"
              className="text-gray-700 hover:text-blue-600 hover:underline"
            >
              Investors
            </Link>
            <Link
              to="/accelerators"
              className="text-gray-700 hover:text-blue-600 hover:underline"
            >
              Accelerators
            </Link>
            <Link
              to="/competitions"
              className="text-gray-700 hover:text-blue-600 hover:underline"
            >
              Competitions
            </Link>
            <Link
              to="/grants"
              className="text-gray-700 hover:text-blue-600 hover:underline"
            >
              Grants
            </Link>
            <Link
              to="/comingsoon"
              className="text-gray-700 hover:text-blue-600 hover:underline"
            >
              Coming Soon
            </Link>
          </div>

          {/* Right: Profile Icon (Hidden on Mobile) */}
          <div className="hidden lg:flex lg:items-center">
            <Link to="/login">
              <UserIcon className="h-6 w-6 text-blue-600 hover:text-blue-700" />
            </Link>
          </div>

          {/* Toggle Button for Mobile */}
          <button
            onClick={toggleMenu}
            className="lg:hidden text-blue-600 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>

        {/* Mobile Menu (Hidden on Desktop/iPad) */}
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } lg:hidden mt-2`}
        >
          <div className="flex flex-col space-y-2">
            <Link
              to="/vcs"
              className="block py-2 text-gray-700 hover:text-blue-600 hover:underline"
            >
              Investors
            </Link>
            <Link
              to="/accelerators"
              className="block py-2 text-gray-700 hover:text-blue-600 hover:underline"
            >
              Accelerators
            </Link>
            <Link
              to="/competitions"
              className="block py-2 text-gray-700 hover:text-blue-600 hover:underline"
            >
              Competitions
            </Link>
            <Link
              to="/grants"
              className="block py-2 text-gray-700 hover:text-blue-600 hover:underline"
            >
              Grants
            </Link>
            <Link
              to="/comingsoon"
              className="block py-2 text-gray-700 hover:text-blue-600 hover:underline"
            >
              Coming Soon
            </Link>
            <Link
              to="/login"
              className="block py-2 text-gray-700 hover:text-blue-600 hover:underline"
            >
              <UserIcon className="h-6 w-6 inline-block" /> Profile
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;