import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { supabase } from '../supabaseClient';

Modal.setAppElement("#root");

const VCsFundsPage = () => {
  const [countryFilter, setCountryFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [thesisFilter, setThesisFilter] = useState("");
  const [stageFilter, setStageFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedVC, setSelectedVC] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchData = async (page = 1) => {
    const limit = 50;
    const offset = (page - 1) * limit;

    try {
      const { data, count, error } = await supabase
        .from('vcs_funds')
        .select('*', { count: 'exact' })
        .range(offset, offset + limit - 1);

      if (error) throw error;

      setFilteredData(data);
      setTotalPages(Math.ceil(count / limit));
    } catch (err) {
      console.error('Error fetching data:', err.message);
    }
  };

  const fetchFilteredData = async (page = 1) => {
    const limit = 50;
    const offset = (page - 1) * limit;

    try {
      let query = supabase
        .from('vcs_funds')
        .select('*', { count: 'exact' })
        .range(offset, offset + limit - 1);

      if (countryFilter) {
        query = query.ilike('countries', `%${countryFilter}%`);
      }
      if (nameFilter) {
        query = query.ilike('name', `%${nameFilter}%`);
      }
      if (thesisFilter) {
        query = query.or(`thesis.ilike.%${thesisFilter}%,area.ilike.%${thesisFilter}%`);
      }
      if (stageFilter) {
        query = query.ilike('stage', `%${stageFilter}%`);
      }
      const { data, count, error } = await query;
      if (error) throw error;

      setFilteredData(data);
      setTotalPages(Math.ceil(count / limit));
    } catch (err) {
      console.error('Error fetching filtered data:', err.message);
      setFilteredData([]);
    }
  };

  const handleApplyFilter = () => {
    setCurrentPage(1);
    fetchFilteredData(1);
  };

  const handleResetFilters = () => {
    setCountryFilter("");
    setNameFilter("");
    setThesisFilter("");
    setStageFilter("");
    setCurrentPage(1);
    fetchData(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleVCClick = (vc) => {
    setSelectedVC(vc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVC(null);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Title */}
      <h1 className="text-4xl font-bold text-center mt-6 mb-4">VCs/Funds</h1>
      <p className="text-lg text-gray-700 text-center ml-12 mr-12 mb-4">
        Welcome to the investors database. Search and query through the 23,000+ rows of data as you'd like!
      </p>

      {/* Mobile View: Filters on Top */}
      <div className="lg:hidden p-4">
        <h2 className="text-lg font-bold text-center mb-4">Filters</h2>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="What industry?"
            value={thesisFilter}
            onChange={(e) => setThesisFilter(e.target.value)}
            className="px-3 py-2 border rounded-lg text-sm w-full"
          />
          <input
            type="text"
            placeholder="Filter by Name"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            className="px-3 py-2 border rounded-lg text-sm w-full"
          />
          <input
            type="text"
            placeholder="Filter by Country"
            value={countryFilter}
            onChange={(e) => setCountryFilter(e.target.value)}
            className="px-3 py-2 border rounded-lg text-sm w-full"
          />
          <select
            value={stageFilter}
            onChange={(e) => setStageFilter(e.target.value)}
            className="px-3 py-2 border rounded-lg text-sm w-full"
          >
            <option value="">Select Stage</option>
            <option value="Seed">Seed</option>
            <option value="Series A">Series A</option>
            <option value="Series B">Series B</option>
            <option value="Series C">Series C</option>
            <option value="Series D">Series D</option>
          </select>
          <button
            onClick={handleApplyFilter}
            className="w-full py-2 bg-blue-600 hover:bg-blue-800 text-white rounded-lg text-sm"
          >
            Apply Filter
          </button>
          <button
            onClick={handleResetFilters}
            className="w-full py-2 bg-black hover:bg-blue-800 text-white rounded-lg text-sm"
          >
            Reset Filters
          </button>
        </div>
        <p className="text-sm text-gray-600 text-center mt-4">
          For a better experience, view this page on a desktop.
        </p>
      </div>

      {/* Desktop View: Filters on Sidebar */}
      <div className="hidden lg:flex w-full px-8">
        <div
          className="bg-gradient-to-b from-gray-800 to-gray-700 shadow-md rounded-lg p-6 sticky top-20 self-start"
          style={{
            minWidth: "350px",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <h2 className="text-lg font-bold text-white mb-4">Filters</h2>
          <div className="space-y-4">
            <input
              type="text"
              placeholder="What industry?"
              value={thesisFilter}
              onChange={(e) => setThesisFilter(e.target.value)}
              className="px-3 py-2 border rounded-lg text-sm w-full"
            />
            <input
              type="text"
              placeholder="Filter by Name"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              className="px-3 py-2 border rounded-lg text-sm w-full"
            />
            <input
              type="text"
              placeholder="Filter by Country"
              value={countryFilter}
              onChange={(e) => setCountryFilter(e.target.value)}
              className="px-3 py-2 border rounded-lg text-sm w-full"
            />
            <select
              value={stageFilter}
              onChange={(e) => setStageFilter(e.target.value)}
              className="px-3 py-2 border rounded-lg text-sm w-full"
            >
              <option value="">Select Stage</option>
              <option value="Seed">Seed</option>
              <option value="Series A">Series A</option>
              <option value="Series B">Series B</option>
              <option value="Series C">Series C</option>
              <option value="Series D">Series D</option>
            </select>
            <button
              onClick={handleApplyFilter}
              className="w-full py-2 bg-blue-600 hover:bg-blue-800 text-white rounded-lg text-sm"
            >
              Apply Filter
            </button>
            <button
              onClick={handleResetFilters}
              className="w-full py-2 bg-black hover:bg-blue-800 text-white rounded-lg text-sm"
            >
              Reset Filters
            </button>
          </div>
        </div>

        {/* Data Table */}
        <div className="flex-grow bg-gray-100 p-4">
          <table className="min-w-full bg-white shadow-md rounded-lg text-sm">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-left">Name</th>
                <th className="py-2 px-4 border-b text-left">Website</th>
                <th className="py-2 px-4 border-b text-left">Company LinkedIn</th>
                <th className="py-2 px-4 border-b text-left">Countries</th>
                <th className="py-2 px-4 border-b text-left">Stage</th>
                <th className="py-2 px-4 border-b text-left">Year Founded</th>
                <th className="py-2 px-4 border-b text-left">Global HQ</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((vc) => (
                <tr key={vc.id} className="h-12">
                  <td
                    className="text-blue-600 py-2 px-4 border-b truncate max-w-[150px] cursor-pointer hover:underline"
                    onClick={() => handleVCClick(vc)}
                  >
                    {vc.name}
                  </td>
                  <td className="py-2 px-4 border-b truncate max-w-[150px]">
                    <a
                      href={vc.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {vc.website}
                    </a>
                  </td>
                  <td className="py-2 px-4 border-b truncate max-w-[150px]">
                    <a
                      href={vc.company_linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {vc.company_linkedin}
                    </a>
                  </td>
                  <td className="py-2 px-4 border-b max-w-xs truncate hover:overflow-x-auto hover:whitespace-normal scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                    {Array.isArray(vc.countries)
                      ? vc.countries.split(",").join(" ")
                      : vc.countries}
                  </td>
                  <td className="py-2 px-4 border-b truncate max-w-xs">
                    <div className="flex flex-wrap">
                      {vc.stage
                        ? vc.stage.split(",").map((tag, index) => (
                            <span
                              key={index}
                              className="inline-block bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded mb-1"
                            >
                              {tag.trim()}
                            </span>
                          ))
                        : ""}
                    </div>
                  </td>
                  <td className="py-2 px-4 border-b truncate max-w-[120px] hover:whitespace-nowrap">
                    {vc.year_founded}
                  </td>
                  <td className="py-2 px-4 border-b truncate max-w-[200px] hover:whitespace-nowrap">
                    {vc.global_hq}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-between w-full max-w-7xl px-8 my-4">
            <button
              disabled={currentPage === 1}
              onClick={handlePreviousPage}
              className="px-4 py-2 rounded-lg bg-blue-500 text-white"
            >
              Previous
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
              className="px-4 py-2 rounded-lg bg-blue-500 text-white"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Mobile View: VC List */}
      <div className="lg:hidden p-4">
        <h2 className="text-lg font-bold text-center mb-4">VCs/Funds</h2>
        <div className="bg-white p-4 rounded-lg shadow-md">
          {filteredData.map((vc, index) => (
            <div key={vc.id}>
              <p
                className="text-blue-600 font-semibold py-2 cursor-pointer hover:underline"
                onClick={() => handleVCClick(vc)}
              >
                {vc.name}
              </p>
              {index !== filteredData.length - 1 && (
                <hr className="border-t border-gray-200 my-2" />
              )}
            </div>
          ))}
        </div>

        <div className="flex justify-between w-full max-w-7xl px-8 my-4">
          <button
            disabled={currentPage === 1}
            onClick={handlePreviousPage}
            className="px-4 py-2 rounded-lg bg-blue-500 text-white"
          >
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button
            disabled={currentPage === totalPages}
            onClick={handleNextPage}
            className="px-4 py-2 rounded-lg bg-blue-500 text-white"
          >
            Next
          </button>
        </div>
      </div>

      {selectedVC && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="VC Details"
          className="bg-white p-6 rounded-lg shadow-lg max-w-3xl mx-auto"
          overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center"
        >
          <h2 className="text-3xl font-bold text-center mb-6">{selectedVC.name}</h2>
          <div className="flex flex-col lg:flex-row">
            {/* Left Section */}
            <div className="w-full lg:w-1/2 pr-4">
              <p>
                <strong>Global HQ:</strong> {selectedVC.global_hq || "N/A"}
              </p>
              <br />
              <p>
                <strong>Countries of Investment:</strong>{" "}
                {Array.isArray(selectedVC.countries)
                  ? selectedVC.countries.split(",").join(" ")
                  : selectedVC.countries || "N/A"}
              </p>
              <br />
              <p>
                <strong>Investing Stage:</strong> {selectedVC.stage || "N/A"}
              </p>
              <br />
              <p>
                <strong>Investment Thesis:</strong> {selectedVC.thesis || "N/A"}
              </p>
              <br />
              <p>
                <strong>Area of Investment:</strong> {selectedVC.area || "N/A"}
              </p>
              <br />
              <p>
                <strong>Type:</strong> {selectedVC.type || "N/A"}
              </p>
            </div>

            {/* Divider */}
            <div className="hidden lg:block w-px bg-gray-300 mx-4"></div>

            {/* Right Section */}
            <div className="w-full lg:w-1/2 pl-4 mt-6 lg:mt-0">
              <p>
                <strong>Website:</strong>{" "}
                <a
                  href={selectedVC.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  {selectedVC.website || "N/A"}
                </a>
              </p>
              <br />
              <p>
                <strong>Company LinkedIn:</strong>{" "}
                <a
                  href={selectedVC.company_linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  {selectedVC.company_linkedin || "N/A"}
                </a>
              </p>
              <br />
              <p>
                <strong>Key People:</strong> {selectedVC.people || "N/A"}
              </p>
              <br />
              <p>
                <strong>Emails:</strong> {selectedVC.emails || "N/A"}
              </p>
              <br />
              <p>
                <strong>Job Title:</strong> {selectedVC.job_title || "N/A"}
              </p>
              <br />
              <p>
                <strong>Average Investment (may be inaccurate):</strong> {selectedVC.avg_investment || "N/A"}
              </p>
            </div>
          </div>
          <button
            onClick={closeModal}
            className="mt-6 bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg block mx-auto"
          >
            Close
          </button>
        </Modal>
      )}
    </div>
  );
};

export default VCsFundsPage;