import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";

const AuthComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
      }
    );

    return () => {
      authListener.subscription?.unsubscribe();
    };
  }, []);

  const handleSignUp = async () => {
    setLoading(true);
    setError(null);
    try {
      const { error } = await supabase.auth.signUp({ email, password });
      if (error) throw error;
      navigate("/vcs");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async () => {
    setLoading(true);
    setError(null);
    try {
      const { error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
      navigate("/vcs");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    setLoading(true);
    setError(null);
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setSession(null);
      navigate("/");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: "https://makerterminal.com/vcs", // Ensure this is the correct URL
        },
      });
  
      if (error) throw error;
    } catch (error) {
      setError(error.message);
      console.error("Google Sign-In Error:", error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white shadow-lg rounded-lg">
        {session ? (
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Welcome, {session.user.email}</h2>
            <button onClick={handleSignOut} disabled={loading} className="w-full py-2 px-4 bg-red-500 hover:bg-red-600 text-white rounded-lg font-semibold">
              {loading ? "Signing Out..." : "Sign Out"}
            </button>
          </div>
        ) : (
          <div>
            <h2 className="text-3xl font-bold text-center mb-6">Sign In / Sign Up</h2>
            <div className="mb-4">
              <button 
                onClick={handleGoogleSignIn} 
                disabled={loading} 
                className="w-full flex items-center justify-center py-2 px-4 border border-gray-300 rounded-lg bg-white text-gray-700 font-semibold hover:bg-gray-100"
              >
                <img 
                  src="https://developers.google.com/identity/images/g-logo.png" 
                  alt="Google logo" 
                  className="h-5 w-5 mr-2"
                />
                {loading ? "Signing In..." : "Sign in with Google"}
              </button>
            </div>
            {!showForm ? (
              <button 
                onClick={() => setShowForm(true)} 
                className="w-full py-2 px-4 bg-gray-600 hover:bg-blue-600 text-white rounded-lg font-semibold mt-2"
              >
                Sign in / Sign up with Email
              </button>
            ) : (
              <div>
                <div className="mb-4">
                  <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={loading} className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
                </div>
                <div className="mb-6">
                  <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} disabled={loading} className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
                </div>
                <button onClick={handleSignUp} disabled={loading} className="w-full py-2 px-4 bg-green-500 hover:bg-green-600 text-white rounded-lg font-semibold">
                  {loading ? "Signing Up..." : "Sign Up"}
                </button>
                <button onClick={handleSignIn} disabled={loading} className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-lg font-semibold mt-2">
                  {loading ? "Signing In..." : "Sign In"}
                </button>
              </div>
            )}
            
            {error && <p className="text-red-500 text-center mt-4">{error}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthComponent;


